import React, { useState, useEffect } from 'react';
import { Link } from '@/Framework/Router/Next/Link';
import PageSeo from '@/finsight/ui/common/components/Seo';
import URL from '@/finsight/Router/urlConstants';
import metaTags from '@/finsight/ui/common/metaTagsConstants';
import MetaSchema from '@/finsight/ui/common/components/MetaSchema';
import getHomepageMetaSchema from '@/finsight/ui/components/static/Homepage/getHomepageMetaSchema';
import CookiesBanner from 'ui/shared/components/Templates/CookiesBanner';

import styles from '@/finsight/ui/components/static/Homepage/homepage.scss';

interface IProps {
  setMetaTags?: (payload: any) => any;
}

const Homepage = ({ setMetaTags }: IProps) => {
  const [offsetLeft, setOffsetLeft] = useState<number>(0);

  useEffect(() => {
    setMetaTags({
      ...metaTags.getHomePage(),
      withSuffix: false
    });
  }, [])

  const changeOffsetLeft = (e) => {
    let { offsetLeft, offsetWidth } = e.target;
    setOffsetLeft(offsetLeft + (offsetWidth / 2));
  };

  return (
    <>
      <MetaSchema schema={ getHomepageMetaSchema() } />
      <PageSeo { ...getHomepageMetaSchema() } { ...metaTags.getHomePage() } />
      <div className={ styles.backgroundImg } />
      <div className={ styles.pageContentWrapper }>
        <div className={ styles.pageTitleContainer }>
          <h1>Illuminating <br /> Capital Markets</h1>
        </div>
        <div className={ styles.pageNavigationContainer }>
          <Link
            title="About Us"
            onMouseEnter={ changeOffsetLeft }
            className={ styles.link }
            to={ URL.ABOUT }
          >
            About Us
          </Link>
          <Link
            title="Solutions"
            onMouseEnter={ changeOffsetLeft }
            className={ styles.link }
            to={ URL.SOLUTIONS }
          >
            Solutions
          </Link>
          <Link
            title="Contact"
            onMouseEnter={ changeOffsetLeft }
            className={ styles.link }
            to={ URL.CONTACT }
          >
            Contact
          </Link>
          <i
            style={ {
              left: offsetLeft
            } }
            className={ styles.bottomLine }
          />
        </div>
      </div>
      <CookiesBanner />
    </>
  );
};

export default Homepage;
